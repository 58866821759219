@use './../utils' as *;

.container {
    padding: 0 rem(20);
    max-width: rem(1684);
    margin: 0 auto;

    @include breakpoint(large) {
        transform: translate(0, 0);
    }
}