.intro-screen{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--white);
    height: 100%;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.slide-hide{
    // font-size: 1rem;
    background-color: #fff;
    overflow: hidden;
}

.slide-hide span{
    color: var(--black);
    // font-size: 3rem;
    line-height: normal;
    transform: translateX(-100%);
    display: inline-block;
}

.slider{
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    height: 100%;
    width: 100%;
    z-index: 3;
    transform: translateX(-100%);
}

.ball {
    width: 35px;
    height: 35px;
    position: fixed;
    top: 0;
    left: 0;
    border: 2px solid var(--purple);
    border-radius: 50%;
}
