@use './../utils' as *;

h1,
h2,
h3 {
    margin-top: 0;
    line-height: 1.1;
}

h1 {
    font-size: rem(62);
    margin-bottom: rem(3);
    font-weight: bold;

    span{
        color: var(--purple);
    }

    @include breakpoint(large) {
        font-size: rem(112);
    }
}

h2 {
    font-size: rem(32);
    font-weight: normal;
    margin-bottom: rem(24);
    @include breakpoint(large) {
        font-size: rem(56);
    }
}

h1,h2{
    color: var(--TextColor);
}

h3{
    color: var(--h3Gray);
    font-weight: normal;
    font-family: var(--font-cursive);
    font-size: rem(24);
    margin-bottom: 1.5rem;

    @include breakpoint(large) {
        font-size: rem(40);
    }

    &::before{
        content: '';
        width: rem(50);
        height: rem(2);
        display: inline-block;
        vertical-align: middle;
        background-color: var(--h3Gray);
        margin-right: rem(10);
        border-radius: rem(10);
    }
}

h4{
    font-size: rem(22);
    @include breakpoint(large) {
        font-size: rem(28.8);
        line-height: rem(36);
    }
    font-weight: normal;
}

a,
a:visited,
a:active {
    text-decoration: none;
    color: var(--purple);
}