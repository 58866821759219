@use './../utils' as *;


.banner{
    position: relative;
    @include breakpoint(xlarge){
        padding: 0;
        .container{
            min-height: rem(720);
            display: flex;

        }
    }
}

.banner-row{
    min-height: rem(400);
    display: flex;
    align-items: center;

    h3{
        font-size: rem(28.8);
    }

    @include breakpoint(xlarge) {
        // height: 100%;
    }

}

canvas.tsparticles-canvas-el{
    position: absolute !important;
}