@use './../utils/' as *;

.skills-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li{
        font-size: rem(40);
        margin: 0 rem(20) rem(15);

        @include breakpoint(large){
            font-size: 8rem;
        }
    }
}