@use './../utils/' as *;

.PostListCard{
    h4{
        font-weight: bold;
        margin-bottom: 0;

        a{
            color: var(--black);
        }
    }

    a{
        font-weight: bold;
    }
    margin-bottom: 2rem;

    @include breakpoint(large) {
        width: calc(50% - 2.5rem);
        margin: 1rem 0.5rem;
        margin-right: 2rem;
    }

}

.postListMeta{
    font-size: rem(14);
    color: var(--metaGray);
}

.postListExcerpt{
    font-size: rem(16);
}

.postcards-row{
    @include breakpoint(large){
        display: flex;
        flex-wrap: wrap;
    }
}