@use './../utils' as *;

.socialMenuList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    li{
        margin-right: rem(15);

        @include breakpoint(xlarge) {
            margin-right: rem(25);
        }
    }
}

@include breakpoint(xlarge){
    .nav {
        .socialMenuList{
            display: flex;
        }
    }

}