@use './../utils/' as *;


.about-row{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > *{
        width: 50%;
    }

    img{
        width: calc(50% - 1.25rem);
        padding: 0 6rem;
    }

}

.about-desc {
    background: rgba(127, 90, 240, 0.20);
    margin-left: rem(20);
    margin-top: rem(50);
    padding: rem(60);   
    position: relative;
    


    &::before{
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        background: rgba(127, 90, 240, 0.10);
        height: 100%;
        width: 100%;
    }

    @include breakpoint(xlarge){
        font-size: 1.5rem;
    }
}