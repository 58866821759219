@use  './../utils/' as *;

footer{
    background-color: var(--purple);
    font-size: rem(20);
    font-weight: bold;
    word-break: break-word;
    a, a:visited, a:active{
        color: var(--white);
        font-weight: normal;
    }
    p{
        margin: 0;
    }
    .container{
        div{
            display: inline-flex;
            align-items: center;
            margin-top: rem(15);
        }
    }
    .socialMenuList{
        margin-left: rem(20);
    }
}