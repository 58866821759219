@use './../utils' as *;

.card {
    background-color: var(--cardGray);
    color: var(--white);
    overflow: hidden;
    margin-bottom: rem(35);
    display: flex;
    flex-direction: column;

    a{
        color: var(--white);
        font-family: var(--font-cursive);
        svg{
            display: block;
            margin: 0 auto;
        }
    }

    .card-header{
        border: 2px solid var(--black);
        border-bottom: none;
        padding: rem(10) rem(12);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        
        font-size: rem(18);

        .three-dots{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::before,
            &::after {
                content: '';
                height: rem(12);
                width: rem(12);
                background-color: var(--white);
                border: rem(2) solid var(--black);
                border-radius: 100%;
                position: relative;
            }

            span {
                height: rem(12);
                width: rem(12);
                background-color: var(--white);
                border: rem(2) solid var(--black);
                border-radius: 100%;
                display: flex;
                align-items: center;
                position: relative;
                margin: 0 rem(5);
            }
        }

        
    }
    .card-body{
        border: 2px solid var(--black);
        position: relative;
        overflow: hidden;
        min-height: rem(300);

        @include breakpoint(xlarge){
            height: 100%;
        }
    }

    .card-content {
        width: 100%;
        transition: all 0.35s ease;
        background: rgba(127,90,240,0.95);
        padding: rem(25) rem(15) rem(15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{ 
            margin-top: 0;
        }
        @include breakpoint(xlarge) {
            position: relative;
            bottom: -100%;
            height: 100%;
            padding-bottom: 6rem;
        }
    }

    .card-image{
        position: absolute;
        height: 100%;
        img{
            display: none;
            @include breakpoint(xlarge) {
                display: block;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    

    .project-links {
        background-color: var(--purple);
        position: absolute;
        z-index: 2;
        display: flex;
        text-align: center;
        align-items: center;
        padding: 1rem 0.5rem;
        bottom: 0;
        width: 100%;
        li{
            flex: 1;
        }
    }

    &:hover{
        .card-content{
            bottom: 0;
        }
    }

    @include breakpoint(large) {
        width: calc(50% - 1rem);
        margin: 1rem 0.5rem;
    }
    @include breakpoint(large) {
        width: calc(33.33% - 1rem);
    }
}

.project-desc{
    // overflow: scroll;
}

@include breakpoint(large) {
    .cards-row{
        display: flex;
        flex-wrap: wrap;
    }
}