:root {
    --white: hsl(0, 0%, 100%);
    --black: #16161A;
    --purple: #7F5AF0;
    --cardGray: #72757E;
}
.theme-dark{
    --bgblack: var(--black);
    --h3Gray: #94A1B2;
    --metaGray: #EBEBEB;
    --TextColor: var(--white);
}

.theme-light{
    --bgblack: var(--white);
    --h3Gray: #565657;
    --metaGray: #888888;
    --TextColor: var(--black);
}

