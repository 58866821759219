@use './../utils' as *;

.nav {
    background-color: var(--purple);
    position: fixed;
    top: rem(10);
    right: rem(10);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 2;
    ul{
        display: none;
        li{
            a{
                color: var(--white);
                font-size: rem(20);
                font-weight: bold;
                cursor: pointer;

                @include breakpoint(large){
                    font-size: rem(32);
                }
            }
        }
    }


    
    .navMenuList{
        margin-bottom: rem(30);
        li{
            margin-bottom: rem(5);
            transition: transform 0.25s ease;

            &:hover{
                transform: rotateZ(-6deg);
                // transform-origin: left;
            }
        }

        @include breakpoint(xlarge){
            margin-bottom: rem(60);
        }
    }
    

    .menuToggle{
        position: absolute;
        top: 0;
        right: 0;
        color: var(--white);
        font-size: rem(20);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(50);
        width: rem(50);
        background-color: var(--purple);
        transition: all 0.5s ease;

        @include breakpoint(xlarge) {
            display: none;
        }

    }

        &.menuOpen {
            padding: rem(30);
            min-width: rem(250);
            min-height: rem(300);

            ul {
                display: block;

                &.socialMenuList {
                    display: flex;
                }
            }

            .menuToggle {
                background-color: var(--white);
                color: var(--purple);
            }
        }

        @include breakpoint(xlarge){
            height: auto;
            top: rem(-24);
            right: 0;
            min-height: rem(548);
            min-width: rem(402);
            padding: 3rem;
            ul{
                display: block;
            }
        }

}